import React from "react";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";
import Feature from "../sections/career/Feature";
import Roles from "../sections/career/Roles";

const Career = () => {
  const intl = useIntl();
  return (
    <>
      <PageWrapper footerDark>
        <Hero title={intl.formatMessage({ id: "jobs" })}>
          <FormattedMessage id="WantToJoinNotes" />
        </Hero>
        <div className="mt-5">
          <Feature />
        </div>
        <Roles />
      </PageWrapper>
    </>
  );
};
export default Career;
